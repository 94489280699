<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-toolbar flat>
              <v-toolbar-title>
              <h1 class="headline">
                  <v-icon color="primary" class="mb-1 mr-2">mdi-account-cog</v-icon>
                  {{ $t('ACCOUNTOPTIONS') }}</h1>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon color="grey" class="mb-1 mr-2">mdi-dots-vertical</v-icon>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">Información Básica</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        outlined
                        v-model="account.name.first"
                        :label="$t('NAME')">
                      </v-text-field>

                      <v-text-field
                        outlined
                        v-model="account.name.last"
                        :label="$t('LASTNAME')">
                      </v-text-field>

                      <v-text-field
                        outlined
                        v-model="account.username"
                        append-icon="mdi-lock"
                        :label="$t('ACCOUNTNAME')">
                      </v-text-field>
                    </v-col>
                    <v-col class="text-center" cols="12" sm="4">
                      <h2 class="mt-4 subtitle-1">Imagen de perfil</h2>
                      <div class="mt-2">
                        <v-hover>
                          <template v-slot:default="{ hover }">
                            <v-avatar class="" color="primary" size="150">
                              <v-img
                              conver
                              src="@/assets/airviva/bg10.jpg" />
                              <v-fade-transition>
                                <v-overlay
                                  v-if="hover"
                                  absolute
                                  color="primary">
                                  <v-btn color="accent" fab small>
                                    <v-icon color="primary">
                                      mdi-camera
                                    </v-icon>
                                  </v-btn>
                                </v-overlay>
                              </v-fade-transition>
                            </v-avatar>
                          </template>
                        </v-hover>
                      </div>
                      <v-btn color="primary" class="mt-2 text-capitalize" text>
                        Eliminar imagen
                      </v-btn> 
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">Información de inicio de sesión</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        outlined
                        v-model="account.email"
                        append-icon="mdi-lock"
                        :label="$tc('EMAIL', 1)">
                      </v-text-field>
                    </v-col>
                    <v-col class="text-right" cols="12" sm="4">
                      <span class="subtitle-1 font-weight-regular">
                      <v-icon color="success">mdi-check</v-icon>Confirmado</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        outlined
                        v-model="account.password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[rules.required, rules.min]"
                        counter
                        @click:append="showPassword = !showPassword"
                        :label="$t('PASSWORD')">
                      </v-text-field>
                    </v-col>
                    <v-col class="text-right" cols="12" sm="4">
                      <v-btn outlined color="primary">Editar la Contraseña</v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        outlined
                        v-model="account.recoverynumber"
                        :label="$t('RECOVERYNUMBER')">
                      </v-text-field>
                    </v-col>
                    <v-col class="text-right" cols="12" sm="4">
                        <v-btn outlined color="primary">Agregar número de teléfono</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">Preferencias de privacidad</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <p class="body-1">Mira y administra las preferencias de cookies y de privacidad de tu cuenta. <a href="#">Leer más</a></p>
                    </v-col>
                    <v-col class="text-right" cols="12" sm="4">
                      <v-btn outlined color="primary">Administrar Preferencias</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text>
              <v-row>
                <v-col>
                  <h2 class="title font-weight-regular">Cerrar tu cuenta</h2>
                  <v-divider class="my-4"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <p class="body-1">Si deseas cerrar tu cuenta, debes aceptar los <a href="#">términos y condiciones de cierre de cuenta</a> establecidos por Arigato.ai</p>
                    </v-col>
                    <v-col class="text-right" cols="12" sm="4">
                      <v-btn outlined color="error">Cerrar Cuenta</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pa-0">
            </v-card-actions>
          </v-card>
        </v-col>
        
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "my-account",
  data() {
    return {
      account: {
        name: {
          first: 'Jhon',
          last: 'Travel',
        },
        username: 'jhontravel',
        email: 'jhontravel@air-viva.com',
        password: '123456789',
        recoverynumber: '+58 (0412) 096.5993'
      },
      overlay: false,
      showPassword: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },

}
</script>

<style>

</style>
